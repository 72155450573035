@tailwind base;
@tailwind components;
@tailwind utilities;


[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-offset-shadow: none !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #dc3a4e;
}

input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */

    width: 20px;
    height: 20px;
    padding: 3px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[class="color-radio"]:checked+div {
    background-color: #F44336;
}

.hidden-radio {
    display: none;
}

.block-radio {
    display: inline-block;
}

.background-gradient {
    background-image: linear-gradient(to right top, #231719, #531d26, #851f2e, #b81d2f, #ea2027);
    ;
}

.w-350 {
    width: 350px;
}

.h-90 {
    height: 90px !important;
}

.font-extraBold {
    font-weight: 800;
}

.h-80 {
    height: 80%;
}

/* optional styles, I'm using this for centering radiobuttons */

.text-2xs {
    font-weight: 100;
}

.custom-image-size {
    height: 520px;
}

.custom-button {
    background: #FF0000;
    color: white;
}

/* .customBotton{
    bottom: none;
} */

.custom-botton {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.custom-header {
    position: fixed;
    top: 0;
    width: 100%;
}

.custom-margin {
    margin-top: 170px !important;
    /* margin-bottom: 150px !important; */
    z-index: 0;
}

.custom-font-size {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

@media (max-width: 500px) {
    .custom-image-size {
        height: 220px;
    }

    .custom-margin {
        margin-top: 100px !important;
        /* margin-bottom: 150px !important; */
        z-index: 0;
    }

    .w-350 {
        width: 322px;
    }

    .custom-font-size {
        font-size: 1.5rem
            /* 30px */
        ;
        line-height: 1.25rem
            /* 36px */
        ;
    }
}

@media (max-height: 700px) {
    .custom-margin {
        margin-top: 96px !important;
        /* margin-bottom: 150px !important; */
        z-index: 0;
    }

    .custom-font-size {
        font-size: 1.875rem
            /* 30px */
        ;
        line-height: 2.25rem
            /* 36px */
        ;
    }
}

/* @media only screen and (min-width: 1281px) {
    .customBotton{
        bottom: 0;
    }
} */


.chatlio-offline-form {
    overflow: hidden !important;
}

.chatlio-chat-header__minimize-button {
    display: none;
}

.chatlio-widget.chatlio-widget-appear .chatlio-widget-body {
    box-shadow: 0px 0px 2px 0px;
}